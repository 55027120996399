import React from "react";
import "./AboutUs.css";
import organizationImage from "./pexels-zlatakycz-8442293.jpg";
import awardsImage from "./pexels-larissa-farber-13423143.jpg";
import visionImage from "./pexels-anna-tarazevich-5598296.jpg";
import foundersImage1 from "./blank-profile-picture-g0c5551188_640.png";
import whoo from "./whoo.jpg";
import { Col, Row } from "reactstrap";
import AboutUsPic1 from "./AboutUsPic1.jpg";
import AboutUsPic2 from "./AboutUsPic2.jpg";

const AboutUs = () => {
  return (
    <div className="container-fluid d-flex justify-content-center" style={{ alignItems:"center", flexDirection:"column"}}>
      {/* Who we are section */}
      <div
        className=" row container d-flex justify-content-center"
        style={{ margin: 0, padding: 0, marginTop: "4rem" }}
      >
        <div
          
          className="col-lg-4 col-md-6 col-sm-12"
          style={{
            alignItems: "center",
          }}
        >
          <img
            src={whoo}
            alt="loading"
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
          />
        </div>
        <div
          
          className="col-lg-8 col-md-6 col-sm-12"
        >
          <h2
            style={{
              color: "#0e3a5a",
              fontWeight: "bolder",
              fontSize: "55px",
              marginBottom: "1rem",
            }}
          >
            Who We Are
          </h2>
          <p className="container" style={{ fontSize: "17px" }}>
            We are a leading college and IT training organization committed to
            providing quality education and empowering individuals with the
            skills needed for success in the digital age. With a rich history of
            academic excellence and a strong focus on practical training, we
            strive to prepare our students for rewarding careers in the
            ever-evolving field of information technology. At our college, we
            offer a comprehensive range of programs and courses designed to meet
            the diverse needs of students. Whether you're a beginner looking to
            start your journey in IT or a professional seeking to upgrade your
            skills, our experienced faculty and industry-relevant curriculum
            ensure that you receive the best education and hands-on training.
            Our state-of-the-art facilities, modern infrastructure, and industry
            partnerships create an ideal learning environment where students can
            gain practical experience and stay updated with the latest
            technologies and trends. We are dedicated to nurturing talent,
            fostering creativity, and equipping our students with the knowledge
            and skills necessary to thrive in the competitive IT industry.
          </p>
        </div>
      </div>

      {/* <section className="Achievements" style={{textAlign: "center"}}>
        <div className="Achievements-left mt-3">
          <h2 style={{color:"#0e3a5a", fontWeight:"bolder"}}>Achievements</h2>
        </div>
        <div className="Achievements-right">
          <img src={awardsImage} alt="Awards" className="awards-logo Achievements-image" />
          <img src={awardsImage} alt="Awards" className="awards-logo Achievements-image" />
          <img src={awardsImage} alt="Awards" className="awards-logo Achievements-image" />
        </div>
      </section> */}

      <section className="section vision-section my-5">
        <div className="group-card-container-AboutUs">
          <div className="group-card-wrapper-AboutUs my-5">
            <Row className="vision-background">
              <Col xlg="12" lg="6" md="12" sm="12" xs="12">
                <div className="group-card-content-AboutUs">
                  <h3
                    className="group-card-subtitle mt-2"
                    style={{ color: "#f4fdff" }}
                  >
                    Our Vision
                  </h3>
                  <p className="group-card-text" style={{ color: "#f4fdff" }}>
                    Our vision is to be a leading institution that empowers
                    individuals with the knowledge and skills required to excel
                    in the field of information technology. We envision a future
                    where education transcends boundaries, where every student
                    has access to high-quality training and can unleash their
                    full potential. Through our commitment to innovation,
                    industry partnerships, and student success, we aim to shape
                    the next generation of IT professionals and contribute to
                    the growth of the technology sector.
                  </p>
                </div>
              </Col>
              <Col xlg="12" lg="6" md="12" sm="12" xs="12">
                <div className="group-card-image">
                  <img src={AboutUsPic1} alt="Vision" className="Vision-Img" />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="group-card-container-AboutUs">
          <div className="group-card-wrapper-AboutUs my-5">
            <Row className="mission-background">
              <Col xlg="12" lg="6" md="12" sm="12" xs="12">
                <div className="group-card-image">
                  <img src={AboutUsPic2} alt="Mission" style={{height:'14rem'}} />
                </div>
              </Col>
              <Col xlg="12" lg="6" md="12" sm="12" xs="12">
                <div className="group-card-content-AboutUs py-3">
                  <h3
                    className="group-card-subtitle mt-2"
                    style={{ color: "#f4fdff" }}
                  >
                    Our Mission
                  </h3>
                  <p className="group-card-text" style={{ color: "#f4fdff" }}>
                    Our mission is to provide high-quality education, training,
                    and industry exposure to aspiring IT professionals. We are
                    dedicated to equipping our students with the necessary
                    technical skills, practical experience, and professional
                    ethics required to thrive in the IT industry. Through a
                    learner-centric approach, collaboration with industry
                    experts, and a focus on hands-on learning, we aim to foster
                    a dynamic learning environment that prepares our students
                    for successful careers and lifelong learning.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="section journey">
        <h2
          style={{ color: "#0e3a5a", fontWeight: "bolder", fontSize: "60px", marginTop:"2rem" }}
        >
          Our Journey
        </h2>
        <div className=" container">
          <p>
            Our journey began with a vision to bridge the gap between education
            and industry requirements in the field of information technology.
            Since our establishment, we have been committed to providing
            exceptional learning opportunities and empowering students to
            succeed in the digital era.
          </p>
          <p>
            Over the years, we have witnessed the transformation of countless
            individuals who have honed their skills, embraced innovation, and
            achieved remarkable milestones in their careers. Our success stories
            are a testament to our unwavering dedication to student growth and
            industry relevance.
          </p>
          <p>
            As we continue on this journey, we remain focused on delivering
            comprehensive and up-to-date IT training, fostering a supportive
            learning community, and staying ahead of emerging trends. We are
            excited about the future and the prospects it holds for our
            students. Together, we will shape the next generation of IT
            professionals and make a positive impact on the technology
            landscape.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
