import React from "react";
import { Card } from "react-bootstrap";
import "../style.css";
import { FaCalendar } from "react-icons/fa";

const SingleCourse = ({ imgUrl, price, title, duration, students }) => {
  return (
    <div>
      <Card className="single-courses">
        <div className="courses_banner_wrapper">
          <div className="courses_banner">
            <a href="#">
              <img
                src={imgUrl}
                alt=""
                className="img-fluid"
                style={{ height: "18rem" }}
              />
            </a>
          </div>
          <div className="purchase_price">
            <a
              href="#"
              className="read_more-btn"
              style={{ marginBottom: "-1rem" }}
            >
              Rs: {price}
            </a>
          </div>
        </div>
        <Card.Body>
          <div className="courses_title my-3 text-center ">
            <a
              className="the-link"
              href="#"
              style={{
                textDecoration: "none",
                fontSize: "25px",
                color: "black",
                fontWeight: "720",
              }}
            >
              {title}
            </a>
          </div>
          <div className="container courses_info">
            <div
              className="row container d-flex justify-content-center"
              style={{ alignItems: "center" }}
            >
              <div className="col-8 ">
                <ul className="list-unstyled">
                  <li className="my-3" style={{ fontSize: "16px" }}>
                    <FaCalendar className="mx-2" /> {duration}
                  </li>
                  <li className="my-3" style={{ fontSize: "16px" }}>
                    <FaCalendar className="mx-2" /> {students} Students
                  </li>
                </ul>
              </div>
              <div className="col-4">
                <a
                  href="https://forms.gle/knUZb2jfZBWF17dQA"
                  className="cart_btn mt-1 the-hover-button"
                  style={{ width: "7rem", fontWeight: "bold" }}
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SingleCourse;
