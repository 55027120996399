import React from "react";
import { Card } from "react-bootstrap";
import { Button, Col, Row } from "reactstrap";
import event1 from "./event_1.jpg";
import event2 from "./event_2.jpg";
import event3 from "./event_3.jpg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { CoPresent } from "@mui/icons-material";

const Events = () => {
  return (
    <div
      className="container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      <Row style={{ width: "80rem" }}>
        <Col lg={6} md={6} sm={12} xs={12}>
          <h1 style={{ fontSize: "3rem", fontWeight: "bolder" }}>
            Our Upcoming Events
          </h1>
          <p>
            Find out what’s on in Gateway Education System. There are numerous
            events which you can attend and follow.
          </p>
        </Col>
        <Col lg={3} md={1} sm={0} xs={12}></Col>
        <Col
          lg={3}
          md={3}
          sm={3}
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "right",
            marginBottom: "5rem",
          }}
        >
          <Button
            className="mt-4"
            variant="contained"
            style={{
              width: "10rem",
              height: "3rem",
              fontSize: "1rem",
              fontWeight: "bold",
              backgroundColor: "#4285F4",
            }}
          >
            VIEW All
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Events;
