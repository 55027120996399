import React, { useState } from "react";
import NavHeader from "../NavHeader/NavHeader";
import { toast } from "react-toastify";
import "../style.css";
import contactUsPic from "./contactUsPic.jpg";
import emailjs from "emailjs-com";

const Contact = () => {
  const courseLinks = [
    { url: "/Home.jsx", title: "Home", text: "Home" },
    {
      url: "/Cotact.jsx",
      title: "Contact Us",
      text: "Contact Details",
      active: true,
    },
  ];

  const [from_name, setName] = useState("");
  const [from_email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [isSent, setIsSent] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if any field is empty
    if (!from_name || !from_email || !message) {
      toast.error("Please fill out all fields.", {
        position: "top-right",
        autoClose: 3000, // 3 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    // Create an object with the form data
    const formData = {
      from_name,
      from_email,
      message,
    };

    emailjs
      .send(
        "service_yhu3w0o",
        "template_3sis9vj",
        formData,
        "TTCODuJE4m7n5amyh"
      )
      .then(
        (result) => {
          console.log(result.text);
          setName("");
          setEmail("");
          setMessage("");
          setIsSent(true);

          // Show a success toast message
          toast.success("Message sent!", {
            position: "top-right",
            autoClose: 3000, // 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error("Error sending message. Please try again later.", {
            position: "top-right",
            autoClose: 3000, // 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      );
  };

  return (
    <>
      <NavHeader
        title="Contact Us"
        backgroundImg={contactUsPic}
        links={courseLinks}
      />

      {/*-------------------- contact section-------------------- */}

      <div className="contact_info_wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="contact_info contact__info_shpae">
                <h3 className="title">Contact Details</h3>
                <p>You can reach us with the following contact details.</p>
                <div className="event_location_info">
                  <ul className="list-unstyled">
                    <li>
                      <h4 className="info_title">Address :</h4>
                      <ul className="list-unstyled">
                        <span style={{ fontWeight: "bold" }}>Head Office</span>{" "}
                        <li>
                          Plot # 74, Chinar Road, I-10/3, Industrial Area,
                          Islamabad
                        </li>
                      </ul>
                      <ul className="list-unstyled mt-2">
                        <span style={{ fontWeight: "bold" }}>
                          Islamabad Campus
                        </span>{" "}
                        <li> 3rd floor, Sadiq Plaza, G-9 Markaz, Islamabad</li>
                      </ul>
                    </li>
                    <li>
                      <h4 className="info_title">Phone:</h4>
                      <ul className="list-unstyled">
                        <li>+92 300 5257465</li>
                        <li>(051) 6127313</li>
                      </ul>
                    </li>
                    <li>
                      <h4 className="info_title">E-mail :</h4>
                      <ul className="list-unstyled">
                        <li>info@gatewayes.org</li>
                      </ul>
                    </li>
                    <li>
                      <h4 className="info_title">Whatsapp :</h4>
                      <ul className="list-unstyled"></ul>
                    </li>
                  </ul>
                  {/* <img src="images/banner/map_shape.png" alt="" className="contact__info_shpae" /> */}
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="contact_form_wrapper">
                <h3 className="title">Get In Touch</h3>
                <div className="leave_comment">
                  <div className="contact_form">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="from_name"
                            placeholder="Your Name"
                            value={from_name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 form-group">
                          <input
                            type="email"
                            className="form-control"
                            id="from_email"
                            placeholder="Your E-mail"
                            value={from_email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 form-group">
                          <textarea
                            className="form-control"
                            id="message"
                            placeholder="Your Comment Write Here ..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 submit-btn">
                          <button type="submit" className="text-center">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
