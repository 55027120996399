import React from "react";
import "./index.css";
import image1 from "./learnstep.jpg";
import image2 from "./about_us.jpg";

const StepToLearn = () => {
  return (
    <div className="main" style={{ background: "#FFFFFF" }}>
      <div
        className="row bg-primary d-flex justify-content-center"
        style={{ alignItems: "center" }}
      >
        <div
          className="col-lg-6 col-md-6 col-sm-12"
          style={{ paddingTop: "9rem", paddingBottom: "9rem" }}
        >
          <div className=" d-flex justify-content-center">
            <img
              className="responsive-image"
              src={image2}
              alt="loading..."
              style={{
                border: "10px solid #FFFFFF",
                maxWidth: "100%", // Make the width responsive
                height: "auto", // Use viewport height (vh) for responsive height
                width: "auto",
                // borderRadius: "50%",
              }}
            />
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div
            className="container "
            // style={{ width: "65%", marginBottom: "6rem" }}
          >
            <h2
              style={{
                color: "black",
                fontSize: "70px",
                fontWeight: "900",
                lineHeight: "65px",
                textAlign: "left",
              }}
            >
              The Story Of Gateway
            </h2>
            <p
              style={{
                marginTop: "30px",
                paddingRight: "10%",
                fontWeight: "400",
                lineHeight: "30px",
                fontSize: "18px",
                color: "white",
              }}
            >
              Gateway Education, a revolutionary school system at the forefront
              of education in Pakistan. With a focus on holistic development and
              cutting-edge pedagogical approaches, Gateway Education is
              transforming traditional classrooms into vibrant spaces of
              creativity, critical thinking, and exploration.
            </p>
          </div>
        </div>
      </div>
      <div
        className="container-fluid row d-flex justify-content-center"
        style={{
          paddingTop: "9rem",
          paddingBottom: "9rem",
          alignItems: "center",
          // marginLeft:"2rem"
        }}
      >
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="container">
            <h2
              style={{
                color: "black",
                fontSize: "50px",
                fontWeight: "900",
                lineHeight: "65px",
                textAlign: "left",
              }}
            >
              Take The First Step To Our IT Training Programs
            </h2>
            <p
              style={{
                fontWeight: "400",
                lineHeight: "30px",
                fontSize: "18px",
                marginTop:'18px'
              }}
            >
              Whether you're a beginner or a seasoned professional, our IT
              training program will equip you with the skills needed to thrive
              in the digital era. As technology continues to transform
              businesses and industries, there is a growing
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="p-0 m-0">
            <img
              className="responsive-image"
              src={image1}
              alt="loading..."
              style={{
                maxWidth: "100%",
                height: "auto",
                // zIndex: 1,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepToLearn;
