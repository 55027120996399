import React, { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "./GatewayLogo.png";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import PropTypes from "prop-types";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const GatewayNavbar = ({ direction, ...args }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
const [programMenuOpen, setProgramMenuOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

   const toggleProgramMenu = () => {
    setProgramMenuOpen(!programMenuOpen);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const pages = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/About" },
    { name: "Academic", path: "/" },
    { name: "Courses", path: "/Courses" },
    // {
    //   name: (
    //     <div className="d-flex">
    //       <UncontrolledDropdown isOpen={programMenuOpen}  toggle={toggleProgramMenu} direction={direction}>
    //         <DropdownToggle
    //           className="bg-light"
    //           style={{
    //             textDecoration: "none",
    //             color: "#1C448E",
    //             fontWeight: "bold",
    //             marginTop: "-0.3rem",
    //             border: "0rem",
    //             fontSize: "1.1rem",
    //           }}
    //           caret
    //         >
    //           Programs
    //         </DropdownToggle>
    //         <DropdownMenu {...args}>
    //           <DropdownItem>Academic</DropdownItem>
    //           <DropdownItem>
    //             <Link to={"/Courses"}>Courses</Link>
    //           </DropdownItem>
    //         </DropdownMenu>
    //       </UncontrolledDropdown>
    //     </div>
    //   ),
    // },
    { name: "Career", path: "/Career" },
    { name: "Gallery", path: "/Gallery" },
    { name: "Contact Us", path: "/Contact" },
  ]

  return (
    <AppBar
      className="d-flex justify-content-center"
      position="static"
      sx={{
        backgroundColor: "#f4fdff",
        color: "#1C448E",
        height: "7rem",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ paddingX: "2rem" }}>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <div style={{ marginTop: ".3rem" }}>
              <img
                style={{ height: "5.5rem", width: "20rem" }}
                src={logo}
                alt="Gateway Logo"
              />
            </div>
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end", // Align pages to the end
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="#0e3a5a"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link
                    to={page.path}
                    style={{
                      textDecoration: "none",
                      color: "#1C448E",
                      fontWeight: "bolder",
                    }}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* For Big screen */}
          <Typography
            variant="h5"
            noWrap
            component={Link}
            to="/"
            style={{ color: "#1C448E" }}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: "bolder",
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <div>
              <img
                style={{ height: "3.5rem", width: "14rem" }}
                src={logo}
                alt="Gateway Logo"
              />
            </div>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "flex-end", // Align pages to the end
            }}
          >
            {pages.map((page) => (
              <Button
                className="mx-3"
                key={page.name}
                component={Link}
                to={page.path}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  color: "#1C448E",
                  textTransform: "capitalize",
                  fontSize: "1rem",
                  fontWeight: "bolder",
                  display: "block",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default GatewayNavbar;
