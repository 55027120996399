import React from "react";
import SliderHome from "./slider/SliderHome";
import SingleCourse from "./SingleCourse";
import LearnMore from "./learnMore/LearnMore";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Events from "./OurUpcomingEvents/Events";
import StepToLearn from "./stepToLearn/StepToLearn";
import StudentsSay from "./studentsSay/StudentsSay";
import image1 from "./home1.jpg";
import image2 from "./home2.jpg";
import image3 from "./home3.jpg";
import image4 from "./home4.jpg";
import image5 from "./home5.jpg";
import image6 from "./home6.jpg";

const Home = () => {
  const coursesData = [
    {
      imgUrl: image1,
      price: "40,000",
      title: "Graphic Designing Course",
      duration: "8 Weeks",
      students: "25",
    },
    {
      imgUrl: image2,
      price: "45,000",
      title: "Web Development Course",
      duration: "8 weeks",
      students: "25",
    },
    {
      imgUrl: image3,
      price: "50,000",
      title: "Digital Marketing Course",
      duration: "8 weeks",
      students: "25",
    },
    {
      imgUrl: image4,
      price: "40,000",
      title: "Amazon VA Developer",
      duration: "8 Weeks",
      students: "25",
    },
    {
      imgUrl: image5,
      price: "50,000",
      title: "App Development",
      duration: "8 Weeks",
      students: "25",
    },
    {
      imgUrl: image6,
      price: "10,000",
      title: "Basic IT course",
      duration: "8 Weeks",
      students: "25",
    },
  ];
  return (
    <>
      {/* slider section */}
      <SliderHome />
      {/* Learn More Section */}
      <LearnMore />
      {/* courses section */}
      {/* Step To Learn Section */}
      <StepToLearn />
      {/* Events Section */}
      <Events />
      {/* Students Say About Us Section */}
      <StudentsSay />
    </>
  );
};

export default Home;
