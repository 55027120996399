
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './App.css';
import Home from './Components/Pages/Home';
import Courses from './Components/Pages/Courses';
import Contact from './Components/Pages/Contact';
import GatewayNavbar from './Components/navbar/GatewayNavbar';
import Footer from './Components/Footer/Footer';
import Gallery from './Components/gallery/Gallery';
import Career from './Components/career/Career';
import AboutUs from './Components/about/AboutUs';
function App() {
 
  return (
   <div>
{/* <SliderHome/> */}
 <ToastContainer />

 <Routes>
   <Route path="/" element={<><GatewayNavbar/><Home/><Footer/></>} />
   <Route path="/Courses" element={ <><GatewayNavbar/><Courses/><Footer/></>} />
   <Route path="/Contact" element={<><GatewayNavbar/><Contact/><Footer/></>} />
   <Route path="/About" element={<><GatewayNavbar/><AboutUs/><Footer/></>} />
   <Route path="/Gallery" element={<><GatewayNavbar/><Gallery/><Footer/></>} />  
   <Route path="/Career" element={<><GatewayNavbar/><Career/><Footer/></>} />      
 </Routes>

    </div>
  );
}

export default App;
