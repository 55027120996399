import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import image1 from "./features_1.jpg";
import image2 from "./features_2.jpg";
import image3 from "./features_3.JPG";
import "./LearnMore.css";
const LearnMore = () => {
  return (
    <div className="bg-light py-5">
       <div
        className="container-fluid row d-flex justify-content-center"
        style={{
          // paddingTop: "9rem",
          // paddingBottom: "9rem",
          alignItems: "center",
          // marginLeft:"2rem"
        }}
      >
         <div className="col-lg-6">
          <h2
            style={{
              fontSize: "50px",
              marginBottom: "40px",
              // letterSpacing: "1px",
              fontWeight: "900",
              textAlign: "left",
            }}
          >
            Take The First Step To Our IT Training Programs
          </h2>
          <p
            style={{
              fontSize: "17px",
              marginBottom: "55px",
              color: "black",
              lineHeight: "30px",
            }}
          >
            Our IT training program offers comprehensive courses that cover
            various aspects of information technology. From programming
            languages to system administration, our courses are designed to meet
            the demands of the rapidly evolving IT industry. Our expert
            instructors bring extensive industry experience to the classroom,
            ensuring that students receive practical knowledge and hands-on
            training.
          </p>
          <Button
            className="the-hover-button"
            variant="contained"
            color="primary"
            style={{
              width: "10rem",
              height: "3rem",
              fontSize: "1rem",
            }}
          >
            Learn More
          </Button>
        </div>
        
        <div className="col-lg-5 col-md-5 col-sm-12 mt-5">
          <Card
            sx={{
              maxWidth: 1500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // border: "2px solid blue",
            }}
          >
            <CardMedia style={{ height: "15rem"}}>
              <img
                className="hover-image"
                src={image2}
                alt="green iguana"
                style={{
                  height: "100%",
                  width: "100%",
                  objectFit:"cover"
                }}
              />
            </CardMedia>
            <CardContent
              style={{
                height: "10rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                Get Your
              </Typography>
              <Button
                size="small"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bolder",
                  textTransform: "capitalize",
                }}
              >
                education equip
              </Button>
            </CardContent>
          </Card>
        </div>
       
      </div>
    </div>
  );
};

export default LearnMore;
