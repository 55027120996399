import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CameraIcon from "@mui/icons-material/PhotoCamera";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";
import image4 from "./image4.jpg";
import image5 from "./image5.jpg";
import image6 from "./image6.jpg";
import image7 from "./image7.jpg";
import image8 from "./image8.jpg";
import back from "./back.jpg";

function CarouselText() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        color: "white",
        textAlign: "center",
        zIndex: 1,
      }}
    >
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="inherit"
        gutterBottom
      >
        Gateway Gallery
      </Typography>
      <Typography variant="h10" align="center" color="inherit" paragraph>
        At Gateway, we strive to provide an exceptional educational experience
        that nurtures the intellectual, emotional, and physical growth of our
        students. Our dedicated faculty and staff are committed to fostering a
        dynamic learning environment that promotes critical thinking,
        creativity, and collaboration.
      </Typography>
    </Box>
  );
}

const cards = [
  { image: image1 },
  { image: image2 },
  { image: image3 },
  { image: image4 },
  { image: image5 },
  { image: image6 },
  { image: image7 },
  { image: image8 },
];

const Gallery = () => {
  return (
    <>
      <main style={{ margin: 0 }}>
        {/* Hero unit */}
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <CardMedia
            component="div"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 0,
              filter: "brightness(50%)",
            }}
            image={back}
          />
          <Container maxWidth="sm">
            <CarouselText />
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map((card) => (
              <Grid item key={card} xs={12} sm={6} md={4} lg={3}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between", // To align content at the bottom of the card
                  }}
                >
                  <CardMedia
                    component="div"
                    sx={{
                      // Increase the size by adjusting the pt value (padding-top)
                      pt: "100%", // Adjust this value as desired
                    }}
                    image={card.image}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </>
  );
};

export default Gallery;
