import React from "react";
import NavHeader from "../NavHeader/NavHeader";
import SingleCourse from "./SingleCourse";
import image1 from "./home1.jpg";
import image2 from "./home2.jpg";
import image3 from "./home3.jpg";
import image4 from "./home4.jpg";
import image5 from "./home5.jpg";
import image6 from "./home6.jpg";
import course1 from "./course1.jpg";
import course2 from "./course2.jpg";
import course3 from "./course3.jpg";
import course4 from "./course4.jpg";
import course5 from "./course5.jpg";
import course6 from "./course6.jpg";
import course7 from "./course7.jpg";
import course8 from "./course8.jpg";
import course9 from "./course9.jpg";
import trainingsImage from "./trainingsImage.jpg";

const Courses = () => {
  const courseLinks = [
    { url: "/Home.jsx", title: "Home", text: "Home" },
    {
      url: "/Courses.jsx",
      title: "Training Programs",
      text: "Training Programs",
      active: true,
    },
  ];

  const coursesData = [
    {
      imgUrl: image4,
      price: "40,000",
      title: "Amazon VA",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: image6,
      price: "10,000",
      title: "Basic IT course",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course1,
      price: "30,000",
      title: "Graphics Designing",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course2,
      price: "40,000",
      title: "Python Programming",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course3,
      price: "40,000",
      title: "E-Commerce",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course4,
      price: "40,000",
      title: "Video Editing",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course5,
      price: "40,000",
      title: "Web Design & Development",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course6,
      price: "40,000",
      title: "Mobile App Development",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course7,
      price: "30,000",
      title: "Digital Marketing",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course8,
      price: "40,000",
      title: "Artificial Intelligence",
      duration: "8 weeks",
      students: "30",
    },
    {
      imgUrl: course9,
      price: "20,000",
      title: "SEO",
      duration: "8 weeks",
      students: "30",
    },
     {
      imgUrl: course2,
      price: "40,000",
      title: "Cyber Security",
      duration: "8 weeks",
      students: "30",
    },
      {
      imgUrl: course4,
      price: "25,000",
      title: "Cloud Computing",
      duration: "8 weeks",
      students: "30",
    },
  ];

  return (
    <>
      <NavHeader
        title="Courses"
        backgroundImg={trainingsImage}
        links={courseLinks}
      />
      {/* --------- courses section---------- */}
      <div className="container mt-5">
        <div className="row">
          {coursesData.map((course, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-4">
              <SingleCourse
                imgUrl={course.imgUrl}
                price={course.price}
                title={course.title}
                duration={course.duration}
                students={course.students}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Courses;
