import Carousel from "react-bootstrap/Carousel";
import homePic1 from "./homePic1.jpg";
import homePic2 from "./homePic2.jpg";
import homePic3 from "./homePic3.jpg";
import homePic4 from "./homePic4.jpg";
import homePic5 from "./homePic5.jpg";
import "./Slider.css";
import { Button } from "@mui/material";
import { useHref, useNavigate } from "react-router-dom";
const items = [
  {
    src: homePic3,
    altText: "Slide 1",
    caption: "Slide 1",
    heading2: "enter the gateway to success",
    // overview: "the goal of education is the advancement of knowledge.",
    key: 1,
  },
  {
    src: homePic2,
    altText: "Slide 2",
    caption: "Slide 2",
    heading2: "take the first step to our IT training programs.",
    // overview: "the goal of education is the advancement of knowledge.",
    key: 2,
  },
  {
    src: homePic4,
    altText: "Slide 2",
    caption: "Slide 2",
    heading2: "learn the right skills.",
    // overview: "the goal of education is the advancement of knowledge.",
    key: 2,
  },
  {
    src: homePic1,
    altText: "Slide 2",
    caption: "Slide 2",
    heading2: "take the first step to our IT training programs.",
    // overview: "the goal of education is the advancement of knowledge.",
    key: 2,
  },
  {
    src: homePic5,
    altText: "Slide 2",
    caption: "Slide 2",
    heading2: "broaden your horizons.",
    // overview: "the goal of education is the advancement of knowledge.",
    key: 2,
  },
];

function Slider() {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleViewCoursesClick = () => {
    navigate("/Courses");
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div>
      <Carousel interval={3000} controls={false} fade className="fade-carousel">
        {items.map((item) => {
          return (
            <Carousel.Item className="position-relative" key={item.key}>
              <div
                className="main"
                style={{
                  height: "90vh",
                  background: "black",
                }}
              >
                <img
                  className="d-block w-100"
                  src={item.src}
                  alt={item.altText}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    opacity: "0.8",
                  }}
                />
                <div className="overlay"></div>
              </div>
              <Carousel.Caption
                style={{
                  marginBottom: "8vw",
                  color: "white",
                  textAlign: "start",
                }}
              >
                <div className="row">
                  {/* <div>
                    {item.overview.split("").map((char, index) => (
                      <span
                        className="per-character-reveal"
                        key={index}
                        style={{
                          animationDelay: `${index * 0.03}s`,
                          marginTop: "0",
                          fontSize: "1.5rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {char}
                      </span>
                    ))}
                  </div> */}
                  <div className="col-12 my-4">
                    {item.heading2.split("").map((char, index) => (
                      <span
                        className="per-character-reveal-heading"
                        key={index}
                        style={{
                          animationDelay: `${index * 0.04}s`,
                          textTransform: "capitalize",
                          fontWeight: "bold",
                          fontSize: "5vw",
                          color: "#f4fdff",
                        }}
                      >
                        {char}
                      </span>
                    ))}
                  </div>
                  <div className="col-12 my-4 button-group">
                    {" "}
                    <a
                      href="https://forms.gle/knUZb2jfZBWF17dQA"
                      style={{ textDecoration: "none" }}
                    >
                      <Button
                        className="mx-2 the-hover-button"
                        variant="contained"
                        color="primary"
                        style={{
                          width: "14rem",
                          height: "4.5rem",
                          fontSize: "1rem",
                          fontWeight: "bold",
                        }}
                        onClick={handleGetStartedClick}
                      >
                        Get Started Now
                      </Button>
                    </a>
                    <Button
                      className="mx-2 the-hover-button"
                      variant="contained"
                      color="primary"
                      style={{
                        width: "14rem",
                        height: "4.5rem",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                      onClick={handleViewCoursesClick}
                    >
                      View Courses
                    </Button>
                  </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default Slider;
