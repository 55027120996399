import React from "react";
import { Button, Col, Row } from "reactstrap";
import bubbleShape from "./bubble_shpe_2.png";
import roundShape from "./round_shpae_1.png";
import footerbg from "./footer_bg_shpe_1.png";
import { Facebook, Instagram, LinkedIn, Twitter } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/Career");
    window.scrollTo(0, 0);
  };

  return (
    <div style={{ color: "white" }}>
      <div
        style={{
          position: "relative",
          color: "white",
        }}
      >
        <Row style={{ margin: 0, padding: 0, position: "relative" }}>
          <Col lg={3} style={{ backgroundColor: "#0b1e42" }}>
            {/* Pseudo-element with background image */}
            <div
              style={{
                content: "",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundImage: `url(${footerbg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                opacity: 0.8,
                zIndex: -1,
              }}
            ></div>
          </Col>
          <Col lg={9} style={{ backgroundColor: "#111b51" }}>
            <Row style={{ padding: "6rem", position: "relative" }}>
              <Col>
                <div>
                  <p style={{ fontWeight: "bolder", fontSize: "24px" }}>
                    Quick Links
                  </p>
                  <ul>
                    <li>Home</li>
                    <li>About Us</li>
                    <li>Course</li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </Col>
              <Col>
                <p style={{ fontWeight: "bolder", fontSize: "24px" }}>
                  Contact Us
                </p>
                <h6 style={{ fontWeight: "bold" }}>Head Office</h6>
                <p>
                  <span style={{ fontWeight: "bold" }}>Address :</span> Plot #
                  74, Chinar Road, I-10/3, Industrial Area, Islamabad{" "}
                </p>
                <h6 style={{ fontWeight: "bold" }}>Phone : </h6>
                <hr />
                <h6 style={{ fontWeight: "bold" }}>Islamabad Campus</h6>
                <p>
                  <span style={{ fontWeight: "bold" }}>Address :</span> 3rd
                  floor, Sadiq Plaza, G-9 Markaz, Islamabad
                </p>
                <h6 style={{ fontWeight: "bold" }}>Phone : (051) 6127313</h6>
                <hr />
                <span
                  style={{
                    marginLeft: "1rem",
                    color: "#4285F4",
                    fontWeight: "bold",
                  }}
                >
                  info@gatewayes.org
                </span>
                <hr />
                <div>
                  <a href="https://www.facebook.com/profile.php?id=61551021658185">
                    <Facebook />
                  </a>{" "}
                  <a href="/">
                    <Twitter />
                  </a>{" "}
                  <a href="https://www.linkedin.com/feed/update/urn:li:activity:7105124040431812608?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7105124040431812608%29">
                    <LinkedIn />
                  </a>{" "}
                  <a href="/">
                    <Instagram />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
