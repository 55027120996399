import React from "react";
import "../style.css";
const NavHeader = ({ title, backgroundImg, links }) => {
  const style = {
    background: `url(${backgroundImg}) no-repeat center center`,
    backgroundSize: "cover",
  };
  return (
    <div className="intro_wrapper bg-dark" style={style}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-8">
            <div className="intro_text">
              <h1>{title}</h1>
              <div className="pages_links">
                {links.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    title={link.title}
                    className={link.active ? "active" : ""}
                  >
                    {link.text} /
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
