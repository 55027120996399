import Carousel from "react-bootstrap/Carousel";
import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";
import "./index.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const items = [
  {
    src: image1,
    altText: "Slide 1",
    caption: "Umair Ali",
    heading2: "Graphic Student",
    overview:
      " I am satisfied with the training and training faculty.It is well designed course with practical orientation.",
    key: 1,
  },
  {
    src: image2,
    altText: "Slide 2",
    caption: "Qasim Khan",
    heading2: "Graphic Student",
    overview:
      "It was one of the best learning experiences I had, at Gateway Education, right from counseling to placements. Counseling: Gateway have the counselors with appropriate industrial knowledge, which helps to identify the need of student.",
    key: 2,
  },
  {
    src: image3,
    altText: "Slide 3",
    caption: "Aryan Malik",
    heading2: "Web Student",
    overview:
      "Overall experience is very good. Guidelines given by trainers were excellent. If you want to optimize your Engineering skills, it’s my personal suggestion to join Gateway Education.",
    key: 3,
  },
];

function StudentsSay() {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate("/Courses");
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleViewCoursesClick = () => {
    navigate("/Courses");
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div
      className="py-5"
      style={{
        background: "linear-gradient(65deg, #5958a0 0%, #111b51 100%)",
      }}
    >
      <div
        className="container row d-flex-justify-content-center"
        style={{ alignItems: "center" }}
      >
        <div className="col-lg-4 col-md-5 col-sm-12">
          <div className="container">
            {" "}
            <h2
              style={{
                fontSize: "40px",
                color: "#ffffff",
                fontWeight: "700",
                lineHeight: "1.2",
              }}
            >
              What Students Say About Gateway
            </h2>
          </div>
        </div>
        <div className="col-lg-8 col-md-7 col-sm-12">
          <Carousel
            interval={3000}
            controls={false}
            fade
            className="fade-carousel"
          >
            {items.map((item) => {
              return (
                <Carousel.Item className="position-relative" key={item.key}>
                  <div
                    className="main"
                    style={{
                      height: "90vh",
                      background: "black",
                    }}
                  >
                    <img
                      className="d-block w-100"
                      src={item.src}
                      alt={item.altText}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        opacity: "0.4",
                      }}
                    />
                    <div className="overlay"></div>
                  </div>
                  <div
                    className="container"
                    style={{
                      color: "white",
                      position: "absolute",
                      top: "20%",
                      width: "100%",
                      textAlign: "left",
                      fontSize: "1.5rem",
                      marginTop: "-5rem",
                    }}
                  >
                    <div className="per-character-reveal">
                      "{item.overview}"
                    </div>
                    <div
                      className="per-character-reveal"
                      style={{ textAlign: "right" }}
                    >
                      <span className="my-2">-{item.caption}</span>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default StudentsSay;
